import './NavTab.css';
import '../Link/Link.css';

function NavTab() {
  return (
    <div className='navtab section'>
      <a href='#aboutProject' className='navtab__link link'>Узнать больше</a>
    </div>)
}

export default NavTab;